/* eslint-disable no-nested-ternary */
import { useLazyQuery } from '@apollo/client';
import { Button, Card, Col, Dropdown, Image, Row, Space, Tag } from 'antd';
import clsx from 'clsx';
import { filter, findIndex, get, includes, map, nth, sortBy } from 'lodash';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../../AppContext';
import {
  AudioIcon,
  CommentBlackIcon,
  DoubleTick,
  Import,
  PauseCircleOutline
} from '../../../../../../../assets/svg';
import {
  BREAKPOINTS,
  DATETIMEWITHDIVIDE,
  DEFAULTDATETIMEFORMAT,
  EQC_STAGE_STATUS_KEYS,
  GA_EVENT,
  GA_LABEL,
  QCSTATUS,
  REPORT_TYPE,
  STAGE_STATUS,
  STAGE_STATUS_CLASSNAME,
  STAGE_STATUS_LABEL
} from '../../../../../../../common/constants';
import { Event } from '../../../../../../../common/trackEvents';
import { timeTaken } from '../../../../../../../common/utils';
import AudioPlayerModal from '../../../../../../../components/AudioPlayerModal';
import CommonCard from '../../../../../../../components/CommonCard';
import CommonSelect from '../../../../../../../components/CommonSelect';
import CommonTable from '../../../../../../../components/CommonTable';
import EllipsisText from '../../../../../../../components/EllipsisText';
import { GET_EQC_STAGE_HISTORY_LIST } from '../../../../../graphql/Queries';
import EqcCommentModal from './EqcCommentModal';
import PauseRemarkModal from './PauseRemarkModal';

const { Option } = CommonSelect;

const EqcStageDetails = ({ stage = {} }) => {
  const [stageData, setStageData] = useState(stage);
  const [showModal, setShowModal] = useState(false);
  const [selectedStageId, setSelectedStageId] = useState();
  const { projectId, eqcId } = useParams();
  const { getToken, getCurrentUser, getCurrentProjectDetail } = useContext(
    AppContext
  );
  const currentProjectDetails = getCurrentProjectDetail();
  const currentUser = getCurrentUser();
  const [stageOptions, setStageOptions] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [previewImageKey, setPreviewImageKey] = useState('');
  const [showAudioModal, setShowAudioModal] = useState(false);
  const [audios, setAudios] = useState([]);
  const [showPauseRemarkModal, setShowPauseRemarkModal] = useState(false);
  const handleSelect = (v) => {
    setSelectedStageId(v);
  };
  const [fetchStageHistoryList, { loading }] = useLazyQuery(
    GET_EQC_STAGE_HISTORY_LIST,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const selectedStage = nth(res?.eqcStageHistoryListByProjectEqc, 0);
        setStageOptions(res?.eqcStageHistoryListByProjectEqc || []);
        setSelectedStageId(selectedStage?.id);
      }
    }
  );
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  useEffect(() => {
    const { status } = stage;
    if (status && status !== EQC_STAGE_STATUS_KEYS.NOT_DONE) {
      fetchStageHistoryList({
        variables: {
          filter: {
            eqcId: Number(eqcId),
            projectEqcTypeStageId: Number(stage?.projectEqcTypeStageId)
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage]);

  useEffect(() => {
    if (selectedStageId) {
      const index = findIndex(stageOptions, ({ id }) => selectedStageId === id);
      if (index !== -1) {
        setStageData(stageOptions[index] || {});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStageId]);

  const handleModal = (record) => {
    setShowModal(record);
  };
  const getQCStatus = (value, original) => {
    const { isSkip, isFailedImmediately = false } = original;
    return (
      <span className="d-flex">
        <div>
          {QCSTATUS[value] === QCSTATUS.PASS && stageData?.isRfiStage ? (
            <DoubleTick className="double-tick-icon" width={18} height={18} />
          ) : (
            <Tag className={STAGE_STATUS_CLASSNAME[value]}>
              {QCSTATUS[value]}
            </Tag>
          )}
        </div>
        {isSkip ? (
          <span style={{ fontSize: 'smaller' }} className="ml-2 text-muted">
            ( skipped )
          </span>
        ) : null}
        {isFailedImmediately ? (
          <Tag className={STAGE_STATUS_CLASSNAME.FAIL}>FI</Tag>
        ) : null}
      </span>
    );
  };
  const getRemarkAudios = (value) => {
    return value?.length ? (
      <div
        className="audio-player-wrapper"
        onClick={() => {
          setShowAudioModal(true);
          setAudios(value);
          Event(GA_EVENT.DOWNLOAD_AUDIO, {
            label: GA_LABEL.DOWNLOAD_AUDIO,
            // eslint-disable-next-line no-undef
            pathname: window?.location?.href
          });
        }}
      >
        <AudioIcon />
        {value?.length - 1 > 0 && (
          <div className="extra-count">{`+${value?.length - 1}`}</div>
        )}
      </div>
    ) : (
      '-'
    );
  };
  const getPhotos = (photos, imageId) => {
    return photos?.length ? (
      <Image.PreviewGroup
        preview={{
          visible: imageId === previewImageKey && isVisible,
          onVisibleChange: (visible) => {
            setIsVisible(visible);
            if (visible) {
              Event(GA_EVENT.OPENED_IMAGE_VIEWER, {
                label: GA_LABEL.OPENED_IMAGE_VIEWER,
                // eslint-disable-next-line no-undef
                pathname: window?.location?.href
              });
            }
          }
        }}
      >
        <Space className="image-preview-wrapper">
          {React.Children.map(photos, (photo) => (
            <Image
              src={photo}
              alt="logo"
              height={isDesktopViewport ? '64px' : '32px'}
              width={isDesktopViewport ? '64px' : '32px'}
              onClick={() => setPreviewImageKey(imageId)}
            />
          ))}
          {photos?.length - 1 > 0 && (
            <div
              className="pointer extra-count"
              onClick={() => {
                setIsVisible(true);
                setPreviewImageKey(imageId);
              }}
            >{`+ ${photos?.length - 1}`}</div>
          )}
        </Space>
      </Image.PreviewGroup>
    ) : (
      '-'
    );
  };

  const getApproverComment = (text, record) => {
    const hasComment =
      !!get(record, 'generalRemark') ||
      get(record, 'generalRemarkImages')?.length > 0;
    return (
      <CommentBlackIcon
        onClick={() => {
          if (hasComment) {
            handleModal(record);
            Event(GA_EVENT.VIEW_GENERAL_REMARK_MODAL, {
              label: GA_LABEL.VIEW_GENERAL_REMARK_MODAL,
              // eslint-disable-next-line no-undef
              pathname: window?.location?.href,
              project_id: projectId,
              project_eqc_id: eqcId,
              project_eqc_stage_id: stageData?.id,
              user_id: currentUser?.id,
              user_name: currentUser?.name,
              tenant_id: currentUser?.tenantUser?.tenant?.id,
              tenant_name: currentUser?.tenantUser?.tenant?.organizationName
            });
          }
        }}
        className={hasComment ? 'has-remark' : 'no-remark'}
      />
    );
  };

  const getIndexOfAC = (val) => {
    const additionalCommentData = filter(
      stageData?.itemHistory,
      (item) => item?.isAdditionalCommentAdded
    );
    const additionalCommentIndex = findIndex(additionalCommentData, (item) => {
      return item?.eqcStageItemId === val?.eqcStageItemId;
    });
    return `AC-${additionalCommentIndex + 1}`;
  };

  const getIndexOfAP = (val) => {
    const additionalPointData = filter(
      stageData?.itemHistory,
      (item) => item?.isAdditionalPointAdded
    );
    const additionalPointIndex = findIndex(additionalPointData, (item) => {
      return item?.eqcStageItemId === val?.eqcStageItemId;
    });
    return `*AP-${additionalPointIndex + 1}`;
  };

  const columns = [
    {
      title: '#',
      width: 80,
      render: (val, y, index) =>
        val?.isAdditionalCommentAdded
          ? getIndexOfAC(val)
          : val?.isAdditionalPointAdded
          ? getIndexOfAP(val)
          : index + 1
    },
    {
      dataIndex: 'title',
      title: 'Checkpoint',
      render: (text) => <EllipsisText text={text || '-'} />
    },
    {
      dataIndex: 'qcOption',
      title: 'Review',
      render: (text) => <EllipsisText text={text || '-'} />
    },
    {
      dataIndex: 'qcStatus',
      title: 'Status',
      render: (value, original) => getQCStatus(value, original)
    },
    {
      dataIndex: 'remarks',
      title: 'Remarks',
      render: (value) => <EllipsisText text={value || '-'} />
    },
    {
      dataIndex: 'remarkAudios',
      title: 'Remark Audios',
      render: (value) => getRemarkAudios(value)
    },
    {
      dataIndex: 'photos',
      title: 'EQC Photos',
      render: (photos, record) => getPhotos(photos, record?.order)
    },
    {
      dataIndex: 'id',
      title: `Approver Comment`,
      render: (text, record) => getApproverComment(text, record)
    }
  ];
  const renderDownloadButton = () => {
    const token = getToken();
    const url = `${process.env.REACT_APP_REPORT_SERVER_URL}/api/v3/report/eqcs/eqc-stage-history-report/${selectedStageId}?access_token=Bearer ${token}`;

    const handleMenuClick = (e) => {
      // eslint-disable-next-line no-undef
      window.open(`${url}&type=${e?.key}`, '_blank');
    };

    const items = [
      {
        key: REPORT_TYPE.PRINTABLE,
        label: <div>Print Version</div>
      },
      {
        key: REPORT_TYPE.SHAREABLE,
        label: <div>Digital Version</div>
      }
    ];

    const menuProps = {
      items,
      onClick: handleMenuClick
    };

    return (
      <Dropdown
        getPopupContainer={() =>
          // eslint-disable-next-line no-undef
          document.querySelector('.eqc-stage-details')
        }
        menu={menuProps}
        trigger={['click']}
        placement="bottomCenter"
        disabled={!selectedStageId}
      >
        <Button
          className="ml-5 report-button"
          icon={<Import width="25px" />}
          shape="round"
          target="_blank"
          disabled={!selectedStageId}
          onClick={() => {
            Event(GA_EVENT.DOWNLOAD_PROJECT_EQC_REPORT, {
              label: GA_LABEL.DOWNLOAD_PROJECT_EQC_REPORT,
              // eslint-disable-next-line no-undef
              pathname: window?.location?.href,
              project_id: projectId,
              project_eqc_id: eqcId,
              project_eqc_stage_id: selectedStageId,
              user_id: currentUser?.id,
              user_name: currentUser?.name,
              tenant_id: currentUser?.tenantUser?.tenant?.id,
              tenant_name: currentUser?.tenantUser?.tenant?.organizationName
            });
          }}
        >
          {isDesktopViewport && 'Download Report'}
        </Button>
      </Dropdown>
    );
  };
  const getUpdatedBy = () => {
    return stageData?.action?.name || '-';
  };

  return (
    <div className="eqc-stage-details">
      <Row className="mt-1" wrap={false}>
        <Col flex="auto">
          {stage?.status && stage?.status !== EQC_STAGE_STATUS_KEYS.NOT_DONE ? (
            <CommonSelect
              onChange={handleSelect}
              className={`${
                isDesktopViewport ? 'width-400' : 'width-percent-100'
              } mb-15`}
              value={selectedStageId}
              showSearch
              optionFilterProp="children"
              loading={loading}
            >
              {map(
                stageOptions,
                ({
                  updatedAt,
                  action: { name },
                  status: stageStatus,
                  id: stageId,
                  eqcApprovalLogs,
                  levelName,
                  eqcStageCompletedAt
                }) => {
                  const stageDetails = nth(eqcApprovalLogs, 0);

                  return (
                    <Option value={stageId} key={stageId}>
                      <div className="whitespace-wrap">
                        {includes(
                          [
                            STAGE_STATUS.APPROVED,
                            STAGE_STATUS.REDO,
                            STAGE_STATUS.ACCEPTED,
                            STAGE_STATUS.REJECTED
                          ],
                          stageStatus
                        )
                          ? stageDetails?.approvalDetails?.name
                          : name}{' '}
                        -{' '}
                        {moment(eqcStageCompletedAt || updatedAt)
                          .tz(currentProjectDetails?.timeZone)
                          .format(DEFAULTDATETIMEFORMAT)}{' '}
                        - {STAGE_STATUS_LABEL[stageStatus]}
                        {levelName &&
                          includes(
                            [
                              STAGE_STATUS.APPROVED,
                              STAGE_STATUS.APPROVAL_PENDING,
                              STAGE_STATUS.REDO,
                              STAGE_STATUS.ACCEPTED,
                              STAGE_STATUS.REJECTED
                            ],
                            stageStatus
                          ) &&
                          ` (${levelName})`}
                      </div>
                    </Option>
                  );
                }
              )}
            </CommonSelect>
          ) : (
            <Col xs="auto">
              <h5 className="inline text-muted mr-3">Stage Summary</h5>
            </Col>
          )}
        </Col>
        {stageData?.eqcPauseLogs?.length > 0 && (
          <Col>
            <Button
              className="ml-5 report-button"
              icon={<PauseCircleOutline width="25px" />}
              shape="round"
              target="_blank"
              disabled={!selectedStageId}
              onClick={() => {
                setShowPauseRemarkModal(true);
                Event(GA_EVENT.VIEW_PAUSE_REMARK_MODAL, {
                  label: GA_LABEL.VIEW_PAUSE_REMARK_MODAL,
                  // eslint-disable-next-line no-undef
                  pathname: window?.location?.href,
                  project_id: projectId,
                  project_eqc_id: eqcId,
                  project_eqc_stage_id: selectedStageId,
                  user_id: currentUser?.id,
                  user_name: currentUser?.name,
                  tenant_id: currentUser?.tenantUser?.tenant?.id,
                  tenant_name: currentUser?.tenantUser?.tenant?.organizationName
                });
              }}
            >
              {isDesktopViewport && 'Pause Remarks'}
            </Button>
          </Col>
        )}
        {(QCSTATUS[stage?.status] ||
          stage?.status === EQC_STAGE_STATUS_KEYS.REDO) && (
          <Col className="d-flex">{renderDownloadButton()}</Col>
        )}
      </Row>
      <div className="approver-stage-details">
        <div>
          {stageData?.skipRemark || stageData?.byPassRemark ? (
            <Card className="comment-card mb-24" loading={loading}>
              {(stageData?.skipRemark || stageData?.byPassRemark) && (
                <div>
                  <div className="remark-title">
                    {stageData?.skipRemark ? 'Skip Remark' : 'Pass Remark'}
                  </div>
                  <div className="d-flex align-center justify-between mb-5">
                    <EllipsisText
                      className="additional-title"
                      text={
                        stageData?.skipRemark || stageData?.byPassRemark || '-'
                      }
                    />
                  </div>
                </div>
              )}
              <Row gutter={10}>
                <Col span={isDesktopViewport ? 12 : 24}>
                  {(stageData?.skipImages?.length > 0 ||
                    stageData?.byPassedImages?.length > 0) &&
                    !loading && (
                      <Row className="flex-vertical mt-24">
                        <div className="remark-title">Remark Images</div>
                        <div
                          className={
                            isDesktopViewport
                              ? 'd-flex justify-between align-end'
                              : 'd-flex flex-vertical'
                          }
                        >
                          <div className="mt-5">
                            {getPhotos(
                              stageData?.skipImages?.length > 0
                                ? stageData?.skipImages
                                : stageData?.byPassedImages,
                              stageData?.skipRemark
                                ? stageData?.skipRemark
                                : stageData?.byPassRemark
                            )}
                          </div>
                        </div>
                      </Row>
                    )}
                </Col>
                <Col span={isDesktopViewport ? 12 : 24}>
                  {(stageData?.skipAudios?.length > 0 ||
                    stageData?.byPassedAudios?.length > 0) &&
                    !loading && (
                      <Row className="flex-vertical mt-24">
                        <div className="remark-title">Remark Audio</div>
                        <div
                          className={
                            isDesktopViewport
                              ? 'd-flex justify-between align-end'
                              : 'd-flex flex-vertical'
                          }
                        >
                          <div className="mt-5">
                            {getRemarkAudios(
                              stageData?.skipAudios?.length > 0
                                ? stageData?.skipAudios
                                : stageData?.byPassedAudios
                            )}
                          </div>
                        </div>
                      </Row>
                    )}
                </Col>
              </Row>
            </Card>
          ) : (
            ''
          )}
        </div>
      </div>
      <Row
        className="mb-15"
        gutter={isDesktopViewport ? 32 : [16, 32]}
        justify="space-between"
        wrap={!isDesktopViewport}
      >
        <Col span={isDesktopViewport ? 4 : 12}>
          <Space direction="vertical" size={12}>
            <div className="text-secondary fw-medium">Inspected By</div>
            <EllipsisText text={getUpdatedBy()} />
          </Space>
        </Col>
        <Col span={isDesktopViewport ? 4 : 12}>
          <Space direction="vertical" size={12}>
            <div className="text-secondary fw-medium">Completed On</div>
            <div>
              {stageData?.eqcStageCompletedAt &&
              stageData?.status &&
              stageData?.status !== EQC_STAGE_STATUS_KEYS.NOT_DONE
                ? moment(stageData?.eqcStageCompletedAt)
                    .tz(currentProjectDetails?.timeZone)
                    .format(DATETIMEWITHDIVIDE)
                : '-'}
            </div>
          </Space>
        </Col>
        <Col span={isDesktopViewport ? 4 : 12}>
          <Space direction="vertical" size={12}>
            <div className="text-secondary fw-medium">Synced On</div>
            <div>
              {stageData?.eqcStageSynceCompletedAt &&
              stageData?.status &&
              stageData?.status !== EQC_STAGE_STATUS_KEYS.NOT_DONE
                ? moment(stageData?.eqcStageSynceCompletedAt)
                    .tz(currentProjectDetails?.timeZone)
                    .format(DATETIMEWITHDIVIDE)
                : '-'}
            </div>
          </Space>
        </Col>
        <Col span={isDesktopViewport ? 4 : 12}>
          <Space direction="vertical" size={12}>
            <div className="text-secondary fw-medium">Stage Status</div>
            {stageData?.status &&
            stageData?.status !== EQC_STAGE_STATUS_KEYS.NOT_DONE ? (
              <Tag className={STAGE_STATUS_CLASSNAME[stageData?.status]}>
                {STAGE_STATUS_LABEL[stageData?.status]}
              </Tag>
            ) : (
              '-'
            )}
          </Space>
        </Col>
        <Col span={isDesktopViewport ? 4 : 12}>
          <Space direction="vertical" size={12}>
            <div className="text-secondary fw-medium">Drawing Title Photos</div>
            {stageData?.drawingTitleImg?.length
              ? getPhotos(stageData?.drawingTitleImg, 'drawingTitleImg')
              : '-'}
          </Space>
        </Col>
        <Col span={isDesktopViewport ? 4 : 12}>
          <Space direction="vertical" size={12}>
            <div className="text-secondary fw-medium">Drawing Photos</div>
            {stageData?.drawingImg?.length
              ? getPhotos(stageData?.drawingImg, 'drawingImg')
              : '-'}
            {stageData?.drawingPDF?.length > 0 && (
              <div>
                <a
                  href={stageData?.drawingPDF?.[0]}
                  target="_blank"
                  className="text-muted"
                  rel="noreferrer nooppener"
                  onClick={() => {
                    Event(GA_EVENT.VIEW_DRAWING_PDF, {
                      label: GA_LABEL.VIEW_DRAWING_PDF,
                      // eslint-disable-next-line no-undef
                      pathname: window?.location?.href,
                      project_id: projectId,
                      project_eqc_id: eqcId,
                      project_eqc_stage_id: selectedStageId,
                      user_id: currentUser?.id,
                      user_name: currentUser?.name,
                      tenant_id: currentUser?.tenantUser?.tenant?.id,
                      tenant_name:
                        currentUser?.tenantUser?.tenant?.organizationName
                    });
                  }}
                >
                  View Drawing
                </a>
              </div>
            )}
          </Space>
        </Col>
      </Row>
      <Row
        className="mb-15"
        gutter={isDesktopViewport ? 32 : [16, 32]}
        justify="space-between"
        wrap={!isDesktopViewport}
      >
        <Col span={isDesktopViewport ? 4 : 12}>
          <Space direction="vertical" size={12}>
            <div className="text-secondary fw-medium">Witnesses</div>
            <div>
              {stageData?.witnessesId && stageData?.witnessesId?.length
                ? map(stageData?.witnessesId, (name, index) => (
                    <p className="mb-5" key={index}>
                      {name}
                    </p>
                  ))
                : '-'}
            </div>
          </Space>
        </Col>
        <Col span={isDesktopViewport ? 4 : 12}>
          <Space direction="vertical" size={12}>
            <div className="text-secondary fw-medium">Time Taken</div>
            <div>
              {stageData?.eqcStageCompletedAt &&
              stageData?.eqcStageStartedAt &&
              stageData?.status &&
              stageData?.status !== EQC_STAGE_STATUS_KEYS.NOT_DONE
                ? timeTaken(
                    stageData?.eqcStageStartedAt,
                    stageData?.eqcStageCompletedAt
                  )
                : '-'}
            </div>
          </Space>
        </Col>
        <Col span={isDesktopViewport ? 4 : 12}>
          <Space direction="vertical" size={12}>
            <div className="text-secondary fw-medium">Witness Photos</div>
            {stageData?.witnessesImg?.length
              ? getPhotos(stageData?.witnessesImg, 'witnessesImg')
              : '-'}
          </Space>
        </Col>
        <Col span={isDesktopViewport ? 4 : 12}>
          <Space direction="vertical" size={12}>
            <div className="text-secondary fw-medium">Agency Name</div>
            <EllipsisText
              text={stageData?.projectAgency?.agency?.name || '-'}
            />
          </Space>
        </Col>
        <Col span={isDesktopViewport ? 4 : 12}>
          <Space direction="vertical" size={12}>
            <div className="text-secondary fw-medium">Location</div>
            <div>
              {stageData?.latitude && stageData?.longitude ? (
                <a
                  href={`https://maps.google.com/?q=${stageData?.latitude},${stageData?.longitude}`}
                  target="_blank"
                  className="text-muted"
                  rel="noreferrer nooppener"
                  onClick={() => {
                    Event(GA_EVENT.VIEW_LOCATION, {
                      label: GA_LABEL.VIEW_LOCATION,
                      // eslint-disable-next-line no-undef
                      pathname: window?.location?.href,
                      project_id: projectId,
                      project_eqc_id: eqcId,
                      project_eqc_stage_id: selectedStageId,
                      user_id: currentUser?.id,
                      user_name: currentUser?.name,
                      tenant_id: currentUser?.tenantUser?.tenant?.id,
                      tenant_name:
                        currentUser?.tenantUser?.tenant?.organizationName
                    });
                  }}
                >
                  View Location
                </a>
              ) : (
                '-'
              )}
            </div>
          </Space>
        </Col>
        {stageData?.isRfiStage ? (
          <Col span={isDesktopViewport ? 4 : 12}>
            <Space direction="vertical" size={12}>
              <div className="text-secondary fw-medium">
                Inspection Requested On
              </div>
              <div>
                {stageData?.inspectorReminderDate &&
                stageData?.status &&
                stageData?.status !== EQC_STAGE_STATUS_KEYS.NOT_DONE
                  ? moment(stageData?.inspectorReminderDate)
                      .tz(currentProjectDetails?.timeZone)
                      .format(DATETIMEWITHDIVIDE)
                  : '-'}
              </div>
            </Space>
          </Col>
        ) : (
          <Col span={isDesktopViewport ? 4 : 12} />
        )}
      </Row>
      {isDesktopViewport ? (
        <CommonTable
          dataSource={sortBy(
            stageData?.itemHistory || stageData?.eqcStageItems,
            'order'
          )}
          columns={columns}
          className="mt-20"
          rowKey="id"
        />
      ) : (
        <div className="mt-15 width-percent-100 infinite-data-container">
          {map(
            sortBy(stageData?.itemHistory || stageData?.eqcStageItems, 'order'),
            (item, index) => {
              const hasComment =
                !!get(item, 'generalRemark') ||
                !!get(item, 'generalRemarkImages');
              return (
                <CommonCard key={item?.id}>
                  <div className="common-card d-flex">
                    <div className="width-percent-100">
                      <div className="card-header fw-medium no-actions">
                        <Row wrap={false}>
                          <div className="mr-5 fw-medium">
                            {item?.isAdditionalCommentAdded
                              ? getIndexOfAC(item)
                              : item?.isAdditionalPointAdded
                              ? getIndexOfAP(item)
                              : index + 1}
                            .
                          </div>
                          <Col flex="auto">
                            <EllipsisText text={item?.title} />
                          </Col>
                          {hasComment && (
                            <Col flex="20px">
                              {getApproverComment(item?.id, item)}
                            </Col>
                          )}
                        </Row>
                      </div>
                      <div className="card-content text-secondary">
                        <br />
                        <div className="mb-15 d-flex">
                          <span className="fw-medium mr-5">Review:</span>
                          <EllipsisText
                            text={item?.qcOption || '-'}
                            className="text-secondary"
                          />
                        </div>
                        <div className="mb-15 d-flex align-center">
                          <span className="fw-medium mr-5">Status:</span>
                          {getQCStatus(item?.qcStatus, item) || '-'}
                        </div>
                        <div className="mb-15 d-flex">
                          <span className="fw-medium mr-5">Remarks:</span>
                          <EllipsisText
                            text={item?.remarks || '-'}
                            className="text-secondary"
                          />
                        </div>
                        <div className="mb-15 d-flex align-center">
                          <span className="fw-medium mr-5">Remark Audios:</span>
                          {getRemarkAudios(item?.remarkAudios)}
                        </div>
                        <div className={clsx(hasComment && 'mb-15')}>
                          {getPhotos(item?.photos, item?.order)}
                        </div>
                      </div>
                    </div>
                    <span className="d-flex position-absolute " />
                  </div>
                </CommonCard>
              );
            }
          )}
        </div>
      )}
      {filter(stageData?.itemHistory, (item) => item?.isAdditionalPointAdded)
        .length > 0 && (
        <div className="ap-info">
          * These are additional points added by inspector through mobile
          application
        </div>
      )}
      <EqcCommentModal showModal={showModal} setShowModal={setShowModal} />
      {audios?.length > 0 && (
        <AudioPlayerModal
          audios={audios}
          showModal={showAudioModal}
          setShowModal={setShowAudioModal}
        />
      )}
      {showPauseRemarkModal && (
        <PauseRemarkModal
          setShowModal={setShowPauseRemarkModal}
          showModal={showPauseRemarkModal}
          selectedStageId={selectedStageId}
        />
      )}
    </div>
  );
};

export default EqcStageDetails;
