import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Alert,
  Button,
  Col,
  Dropdown,
  Menu,
  Popover,
  Row,
  Tooltip
} from 'antd';
import clsx from 'clsx';
import { findIndex, includes, map, sortBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../../../AppContext';
import {
  AddButton,
  DeleteButton,
  DragIcon,
  EditButton,
  InfoIconDark,
  KebabMenu,
  RightIcon,
  WrongIcon
} from '../../../../../../../../assets/svg';
import {
  ADDONS,
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  DEFAULT_PAGE_SIZE,
  GA_EVENT,
  GA_LABEL,
  PROJECT_CHECKLIST_STATUS
} from '../../../../../../../../common/constants';
import { Event } from '../../../../../../../../common/trackEvents';
import { titleCase } from '../../../../../../../../common/utils';
import CanPerform from '../../../../../../../../components/CanPerform';
import CommonCard from '../../../../../../../../components/CommonCard';
import CommonTable from '../../../../../../../../components/CommonTable';
import EllipsisText from '../../../../../../../../components/EllipsisText';
import InfiniteScrollHandler from '../../../../../../../../components/InfiniteScrollHandler';
import Portal from '../../../../../../../../components/Portal';
import {
  DELETE_PROJECT_STAGE_ITEM,
  REORDER_PROJECT_STAGE_ITEM,
  UPDATE_PROJECT_EQC_STAGE_ITEM
} from '../../../../../../graphql/Mutation';
import { GET_PROJECT_STAGE_ITEM_LIST } from '../../../../../../graphql/Queries';
import DeleteModalStage from '../DeleteModalStage';
import AddEditModal from './AddEditModal';

const StageItemTable = ({
  stageId,
  eqcTypeData,
  isEditable,
  selectedStageData
}) => {
  const initialStageItemFilter = {
    skip: 0,
    limit: 10,
    sortBy: { field: 'order', order: 'ASC' }
  };

  const initialPaginationValue = {
    total: 0,
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE
  };

  const DragHandle = sortableHandle(() => (
    <DragIcon className="drag-icon" onClick={(e) => e.preventDefault()} />
  ));
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [showModal, setShowModal] = useState(false);
  const [stageItemData, setStageItemData] = useState();
  const [deleteStageItemModal, setDeleteStageItemModal] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const [stageItemFilter, setStageItemFilter] = useState({
    ...initialStageItemFilter,
    projectEqcTypeStageId: parseInt(stageId, 10)
  });
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [stageItemListData, setStageItemListData] = useState();
  const { projectId } = useParams();
  const [fetchStageItemData, { loading }] = useLazyQuery(
    GET_PROJECT_STAGE_ITEM_LIST,
    {
      variables: { filter: stageItemFilter },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const data = res?.projectEqcTypeStageItemList?.data;
        const pagination = {
          ...paginationProp,
          total: res?.projectEqcTypeStageItemList?.total
        };
        if (scrollFlag) {
          const datalist = [...stageItemListData, ...data];
          setStageItemListData(sortBy(datalist, ['order']));
          setScrollFlag(false);
        } else {
          const datalist = [...data];
          setStageItemListData(sortBy(datalist, ['order']));
        }
        setHasMore(!!data?.length);
        setPaginationProp(pagination);
      },
      onError() {}
    }
  );
  const handleRefetchAfterDelete = () => {
    const newSkip =
      stageItemListData?.length === 1
        ? Math.max(0, stageItemFilter?.skip - paginationProp?.pageSize)
        : stageItemFilter?.skip;
    setStageItemFilter({
      ...stageItemFilter,
      skip: newSkip
    });
    fetchStageItemData({
      variables: {
        filter: {
          ...stageItemFilter,
          skip: newSkip
        }
      }
    });
  };
  const [updateProjectEqcTypeStageItem] = useMutation(
    UPDATE_PROJECT_EQC_STAGE_ITEM,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.DELETE_PROJECT_CHECKLIST_STAGE_ITEM, {
          label: GA_LABEL.DELETE_PROJECT_CHECKLIST_STAGE_ITEM,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_stage_id: stageId,
          stage_item_id: selectedData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        setDeleteStageItemModal(false);
        handleRefetchAfterDelete();
      }
    }
  );

  const [deleteStageItem] = useMutation(DELETE_PROJECT_STAGE_ITEM, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.DELETE_PROJECT_CHECKLIST_STAGE_ITEM, {
        label: GA_LABEL.DELETE_PROJECT_CHECKLIST_STAGE_ITEM,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: projectId,
        project_stage_id: stageId,
        stage_item_id: selectedData?.id,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
      setDeleteStageItemModal(false);
      handleRefetchAfterDelete();
    }
  });

  const [reorderStageItem, { loading: reorderLoading }] = useMutation(
    REORDER_PROJECT_STAGE_ITEM,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.REORDER_PROJECT_CHECKLIST_STAGE_ITEM, {
          label: GA_LABEL.REORDER_PROJECT_CHECKLIST_STAGE_ITEM,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_stage_id: stageId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        fetchStageItemData({ variables: { filter: stageItemFilter } });
      }
    }
  );

  useEffect(() => {
    if (stageId) {
      setStageItemFilter({
        ...initialStageItemFilter,
        projectEqcTypeStageId: parseInt(stageId, 10)
      });
      setPaginationProp(initialPaginationValue);
      fetchStageItemData({
        variables: {
          filter: {
            ...initialStageItemFilter,
            projectEqcTypeStageId: parseInt(stageId, 10)
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageId]);

  const refetchStageDetails = () => {
    fetchStageItemData({
      variables: {
        filter: {
          ...stageItemFilter,
          projectEqcTypeStageId: parseInt(stageId, 10)
        }
      }
    });
  };

  const handleAddEdit = (record) => {
    if (record) {
      setStageItemData(record);
    } else {
      setStageItemData();
    }
    setShowModal(true);
  };

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setStageItemFilter({
      ...stageItemFilter,
      skip: skip,
      limit: pagination.pageSize
    });
    setPaginationProp({ ...paginationProp, ...pagination });
    fetchStageItemData({
      variables: {
        filter: {
          ...stageItemFilter,
          skip,
          limit: pagination.pageSize
        }
      }
    });
  };
  const handleActiveAndDelete = (record) => {
    setDeleteStageItemModal(true);
    setSelectedData(record);
  };
  const getChangeLogInfo = (record) => {
    const logKeys = ['description', 'photo', 'isActive', 'remark'];
    const changeData = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const element in record?.previousHistory) {
      if (
        includes(logKeys, element) &&
        record[element] !== record?.previousHistory[element]
      ) {
        const messageData = {};
        if (element === 'photo' || element === 'remark') {
          messageData.message = `${titleCase(element)} requirement is turned ${
            record[element] ? 'on' : 'off'
          }`;
        } else if (element === 'isActive') {
          messageData.message = `Checkpoint is ${
            record[element] ? 'activated' : 'deactivated'
          }`;
        } else {
          messageData.message = `Item ${element} updated`;
        }
        changeData.push(messageData);
      }
    }
    if (!record?.projectEqcTypeStageItemId) {
      const message = `New checkpoint added`;
      changeData.push({ message });
    }

    return (
      includes(
        [PROJECT_CHECKLIST_STATUS.PUBLISHED, PROJECT_CHECKLIST_STATUS.ARCHIVED],
        eqcTypeData?.projectEqcType?.status
      ) &&
      changeData?.length > 0 && (
        <Popover
          overlayClassName="checklist-changelog-popup"
          title="Changes made to the main file"
          trigger={isDesktopViewport ? 'hover' : 'click'}
          placement={isDesktopViewport ? 'right' : 'top'}
          destroyTooltipOnHide
          getTooltipContainer={(triggerNode) => triggerNode?.parentNode}
          content={
            <div onClick={(e) => e.stopPropagation()}>
              <ul>
                {map(changeData, (data) => {
                  return <li>{data?.message}</li>;
                })}
              </ul>
            </div>
          }
        >
          <InfoIconDark
            width={20}
            height={20}
            className={clsx(isDesktopViewport ? 'mr-20' : 'mr-5', 'info-icon')}
          />
        </Popover>
      )
    );
  };
  const getActionButtons = (record) => {
    return (
      <CanPerform
        action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_CHECKLISTS}
        type={ALLOWED_ACTION_TYPE.BOTH}
      >
        {isEditable ? (
          <div className={clsx(isDesktopViewport && 'd-flex', 'action-icons')}>
            {getChangeLogInfo(record)}
            <Tooltip
              title={
                (eqcTypeData?.status ===
                  PROJECT_CHECKLIST_STATUS?.UNPUBLISHED ||
                  !eqcTypeData?.project?.isActive) &&
                'Edit'
              }
            >
              <Button
                shape="round"
                icon={<EditButton />}
                className={clsx(
                  'edit-button pointer b-0',
                  (eqcTypeData?.status ===
                    PROJECT_CHECKLIST_STATUS?.PUBLISHED ||
                    eqcTypeData?.status ===
                      PROJECT_CHECKLIST_STATUS?.ARCHIVED ||
                    !eqcTypeData?.project?.isActive) &&
                    'disabled-button'
                )}
                disabled={!record?.isActive}
                onClick={() => {
                  handleAddEdit(record);
                }}
              />
            </Tooltip>
            {!includes(
              [
                PROJECT_CHECKLIST_STATUS.PUBLISHED,
                PROJECT_CHECKLIST_STATUS.ARCHIVED
              ],
              eqcTypeData?.projectEqcType?.status
            ) && (
              <Tooltip
                title={
                  (eqcTypeData?.status ===
                    PROJECT_CHECKLIST_STATUS?.UNPUBLISHED ||
                    !eqcTypeData?.project?.isActive) &&
                  'Delete'
                }
              >
                <Button
                  className={clsx(
                    'delete-button pointer b-0',
                    (eqcTypeData?.status ===
                      PROJECT_CHECKLIST_STATUS?.PUBLISHED ||
                      eqcTypeData?.status ===
                        PROJECT_CHECKLIST_STATUS?.ARCHIVED ||
                      !eqcTypeData?.project?.isActive) &&
                      'disabled-button'
                  )}
                  shape="round"
                  icon={<DeleteButton />}
                  disabled={
                    eqcTypeData?.status ===
                      PROJECT_CHECKLIST_STATUS?.PUBLISHED ||
                    eqcTypeData?.status ===
                      PROJECT_CHECKLIST_STATUS?.ARCHIVED ||
                    !eqcTypeData?.project?.isActive
                  }
                  onClick={() => {
                    handleActiveAndDelete(record);
                  }}
                />
              </Tooltip>
            )}
            {includes(
              [
                PROJECT_CHECKLIST_STATUS.PUBLISHED,
                PROJECT_CHECKLIST_STATUS.ARCHIVED
              ],
              eqcTypeData?.projectEqcType?.status
            ) && (
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      key={record?.isActive ? 'deactivate' : 'activate'}
                      onClick={() => {
                        handleActiveAndDelete(record);
                      }}
                    >
                      {record?.isActive ? 'Deactivate' : 'Activate'}
                    </Menu.Item>
                  </Menu>
                }
                getPopupContainer={(triggerNode) => triggerNode?.parentNode}
                trigger={['click']}
              >
                <Button
                  shape="round"
                  className="kebab-icon"
                  icon={<KebabMenu />}
                  onClick={(e) => e.stopPropagation()}
                />
              </Dropdown>
            )}
          </div>
        ) : (
          <div className="d-flex justify-end">
            <div
              className="pointer text-underline mr-10"
              onClick={() => {
                handleAddEdit(record);
              }}
            >
              View
            </div>
          </div>
        )}
      </CanPerform>
    );
  };
  const columns = [
    ...(isEditable
      ? [
          {
            width: '5%',
            className: 'drag-visible',
            render: () =>
              eqcTypeData?.project?.isActive && (
                <Tooltip title="Change order">
                  <div>
                    <DragHandle />
                  </div>
                </Tooltip>
              )
          }
        ]
      : []),
    {
      title: 'ID',
      key: 'id',
      render: (text, record, index) => {
        return <div>{stageItemFilter?.skip + index + 1}</div>;
      }
    },
    {
      title: 'CHECKPOINT',
      dataIndex: 'title',
      key: 'title',
      render: (record) => (
        <div>
          <EllipsisText text={record} />
        </div>
      )
    },
    {
      title: 'INPUT',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'PHOTOS',
      key: 'photo',
      render: (record) => {
        if (record?.photo === true) {
          return <RightIcon />;
        }
        return <WrongIcon className="wrong-icon" />;
      }
    },
    {
      title: 'REMARKS',
      key: 'remark',
      render: (record) => {
        if (record?.remark === true) {
          return <RightIcon />;
        }
        return <WrongIcon className="wrong-icon" />;
      }
    },
    {
      key: 'action',
      render: (record) => getActionButtons(record)
    }
  ];

  const SortableItem = sortableElement((props) =>
    isDesktopViewport ? (
      <tr {...props} />
    ) : (
      <div {...props} className="ant-card ant-card-bordered" />
    )
  );
  const SortableContainer = sortableContainer((props) =>
    isDesktopViewport ? <tbody {...props} /> : <div {...props} />
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      reorderStageItem({
        variables: {
          data: {
            stageItemIds: [
              stageItemListData[oldIndex]?.id,
              stageItemListData[newIndex]?.id
            ],
            projectEqcTypeStageId: parseInt(stageId, 10)
          }
        }
      });
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      axis="y"
      lockAxis="y"
      lockOffset={['0%', '100%']}
      // eslint-disable-next-line no-undef
      helperContainer={document.getElementById('sortable-container-div')}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = findIndex(
      stageItemListData,
      (item) => item?.id === restProps['data-row-key']
    );
    if (index !== -1)
      return (
        <SortableItem index={index} {...restProps} className={className} />
      );
    return null;
  };
  const handleRefetch = () => {
    fetchStageItemData({
      variables: {
        filter: {
          ...stageItemFilter,
          skip: stageItemListData?.length,
          limit: DEFAULT_PAGE_SIZE
        }
      }
    });
  };
  return (
    <div id="project-stage-item-module">
      {showModal && (
        <AddEditModal
          showModal={showModal}
          setShowModal={setShowModal}
          stageData={stageItemData}
          selectedStageData={selectedStageData}
          isUpdate={!!stageItemData}
          setStageData={setStageItemData}
          refetchStageDataWithInitialValues={refetchStageDetails}
          stageId={stageId}
          eqcTypeData={eqcTypeData}
          isDisabled={
            eqcTypeData?.status === PROJECT_CHECKLIST_STATUS?.PUBLISHED ||
            eqcTypeData?.status === PROJECT_CHECKLIST_STATUS?.ARCHIVED ||
            !eqcTypeData?.project?.isActive
          }
          isEditable={isEditable}
        />
      )}
      <DeleteModalStage
        showModal={deleteStageItemModal}
        setShowModal={setDeleteStageItemModal}
        data={selectedData}
        mutation={
          !includes(
            [
              PROJECT_CHECKLIST_STATUS.PUBLISHED,
              PROJECT_CHECKLIST_STATUS.ARCHIVED
            ],
            eqcTypeData?.projectEqcType?.status
          )
            ? deleteStageItem
            : updateProjectEqcTypeStageItem
        }
        stageId={stageId}
        isDeletable={
          !includes(
            [
              PROJECT_CHECKLIST_STATUS.PUBLISHED,
              PROJECT_CHECKLIST_STATUS.ARCHIVED
            ],
            eqcTypeData?.projectEqcType?.status
          )
        }
      />
      {eqcTypeData?.project?.isActive && isEditable && (
        <CanPerform
          action={ALLOWED_ACTION_KEYS.ADD_PROJECT_STAGE_ITEM}
          type={ALLOWED_ACTION_TYPE.BOTH}
        >
          <Portal portalId="stageitem-add-btn">
            <Button
              shape="round"
              type="primary"
              id="add-btn"
              icon={<AddButton />}
              className="add-button"
              disabled={
                eqcTypeData?.status === PROJECT_CHECKLIST_STATUS?.PUBLISHED ||
                eqcTypeData?.status === PROJECT_CHECKLIST_STATUS?.ARCHIVED ||
                !eqcTypeData?.project?.isActive
              }
              onClick={() => {
                handleAddEdit();
              }}
            >
              Add
            </Button>
          </Portal>
        </CanPerform>
      )}
      {selectedStageData?.type === ADDONS.RFI && (
        <Alert
          message={
            <>
              This is a view-only tab. System admin or Project admin can make
              changes by clicking on the Project <b>Settings</b> button and
              click
              <b> RFI</b>.
            </>
          }
          type="info"
          showIcon
          className="mb-10 font-size-12"
        />
      )}
      {isDesktopViewport ? (
        <CommonTable
          columns={columns}
          data={stageItemListData || []}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowKey="id"
          loading={loading || reorderLoading}
          rowClassName={(record) => {
            if (!record?.isActive) return 'row-disabled';
          }}
          components={{
            body: stageItemListData?.length > 0 && {
              wrapper: DraggableContainer,
              row: DraggableBodyRow
            }
          }}
        />
      ) : (
        <InfiniteScrollHandler
          scrollFlag={scrollFlag}
          loading={loading || reorderLoading}
          refetchData={handleRefetch}
          setScrollFlag={setScrollFlag}
          hasMore={hasMore}
          wrapperClassName="stage-item-scroll-wrapper"
          dataLength={stageItemListData?.length}
          skeletonRows={columns?.length - 3}
        >
          <div id="sortable-container-div" />
          <DraggableContainer>
            {map(stageItemListData, (item, index) => {
              return (
                <SortableItem index={index}>
                  <CommonCard key={item?.id} className="stage-card">
                    <div className="common-card d-flex">
                      <div className="mr-5 fw-medium">
                        {isEditable && eqcTypeData?.project?.isActive && (
                          <DragHandle />
                        )}
                      </div>
                      <div>
                        <div className="card-header fw-medium">
                          <Row wrap={false}>
                            <Col flex="auto" className="d-flex">
                              <span className="mr-5">{index + 1}.</span>
                              <EllipsisText text={item?.title} />
                            </Col>
                          </Row>
                        </div>
                        <div className="card-content text-secondary">
                          <br />
                          <div className="mb-15">
                            <span className="fw-medium mr-5">Input:</span>
                            {item?.type}
                          </div>
                          <div className="mb-15 d-flex align-center">
                            <span className="fw-medium mr-5">Photos:</span>
                            {item?.photo === true ? (
                              <RightIcon />
                            ) : (
                              <WrongIcon className="wrong-icon" />
                            )}
                          </div>
                          <div className=" d-flex align-center">
                            <span className="fw-medium mr-5">Remarks:</span>
                            {item?.remark === true ? (
                              <RightIcon />
                            ) : (
                              <WrongIcon className="wrong-icon" />
                            )}
                          </div>
                        </div>
                      </div>
                      <span className="d-flex position-absolute stage-item-action-btn">
                        {getActionButtons(item)}
                      </span>
                    </div>
                  </CommonCard>
                </SortableItem>
              );
            })}
          </DraggableContainer>
        </InfiniteScrollHandler>
      )}
    </div>
  );
};

export default StageItemTable;
