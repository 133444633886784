import { Button, Col, Dropdown, Menu, Row } from 'antd';
import axios from 'axios';
import * as eva from 'eva-icons';
import { includes } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../AppContext';
import { toast } from '../../apollo';
import { DownArrow } from '../../assets/svg';
import {
  ALLOWED_ACTION_KEYS,
  BREAKPOINTS,
  DEFAULTDATEFORMAT,
  GA_EVENT,
  GA_LABEL,
  PROJECT_CHECKLIST_STATUS,
  REPORTS_TAB_API_ROUTES,
  SYSTEM_ROLES,
  WARNINGS
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import CanPerform from '../../components/CanPerform';
import CommonCard from '../../components/CommonCard';
import CommonDropdown from '../../components/CommonDropdown';
import ConfirmationModal from './ConfirmationModal';
import {
  GET_PROJECT_AGENCY_LIST,
  GET_PROJECT_DROPDOWN_LIST,
  GET_PROJECT_EQC_TYPE_LIST,
  GET_PROJECT_USER_LIST
} from './graphql/Queries';

const ProjectReport = ({ dateSelected, fromDate, toDate }) => {
  const { getCurrentUser, getToken, getTenantUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const tenantId = getTenantUser()?.tenant?.id;
  const [projectSelected, setProjectSelected] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [projectAgencyId, setProjectAgencyId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [projectEqcTypeId, setProjectEqcTypeId] = useState();
  const [currentUsersPage, setCurrentUsersPage] = useState(0);
  const [currentAgencyPage, setCurrentAgencyPage] = useState(0);
  const [currentEqcTypePage, setCurrentEqcTypePage] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const isMobileViewport = useMedia(`(max-width: ${BREAKPOINTS.mobile}px)`);
  const token = getToken();
  const getUrl = (
    fetchToken,
    fetchFromDate,
    fetchToDate,
    fetchProjectId,
    fetchUserId,
    fetchAgencyId,
    fetchProjectEqcTypeId,
    endpoint,
    userTenantId
  ) => {
    let sendUrl;
    const url = `${process.env.REACT_APP_REPORT_SERVER_URL}${endpoint}?access_token=Bearer ${fetchToken}&fromDate=${fetchFromDate}&toDate=${fetchToDate}`;
    if (!fetchProjectId) {
      sendUrl = `${url}`;
      if (userTenantId) {
        sendUrl = `${sendUrl}&tenantId=${userTenantId}`;
      }
      return sendUrl;
    }

    if (fetchProjectId) {
      let projectUrl = `${url}&projectId=${fetchProjectId}`;
      if (fetchUserId) {
        projectUrl = `${projectUrl}&userId=${fetchUserId}`;
      }
      if (fetchAgencyId) {
        projectUrl = `${projectUrl}&agencyId=${fetchAgencyId}`;
      }
      if (fetchProjectEqcTypeId) {
        projectUrl = `${projectUrl}&projectEqcTypeId=${fetchProjectEqcTypeId}`;
      }
      if (userTenantId) {
        projectUrl = `${projectUrl}&tenantId=${userTenantId}`;
      }
      return projectUrl;
    }
  };
  const downloadCsv = async (endpoint, userTenantId) => {
    if (!dateSelected) {
      return toast({
        message: 'Select the Date Range',
        type: 'error'
      });
    }
    setLoading(true);
    const url = getUrl(
      token,
      fromDate,
      toDate,
      projectId,
      userId,
      projectAgencyId,
      projectEqcTypeId,
      endpoint,
      userTenantId
    );
    await axios
      .get(url)
      .then(() => {
        let str = `From: ${fromDate}, To: ${toDate}`;
        if (projectId) {
          str += `, Project Id: ${projectId}`;
        }
        if (userId) {
          str += `, User Id: ${userId}`;
        }
        if (projectAgencyId) {
          str += `, Project Agency Id: ${projectAgencyId}`;
        }
        if (projectEqcTypeId) {
          str += `, Project Checklist Id: ${projectEqcTypeId}`;
        }
        Event(GA_EVENT.DOWNLOAD_PROJECT_REPORT, {
          label: GA_LABEL.DOWNLOAD_PROJECT_REPORT,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          query: str,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        // eslint-disable-next-line no-undef
        window.location = url;
      })
      .catch((error) => {
        if (error?.response?.status !== 200) {
          toast({
            message: error?.response?.data?.message,
            type: 'error'
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGenerateClick = () => {
    if (!dateSelected) {
      return toast({
        message: 'Select the Date Range',
        type: 'error'
      });
    }
    if (
      moment(toDate, DEFAULTDATEFORMAT).diff(
        moment(fromDate, DEFAULTDATEFORMAT),
        'days'
      ) > 31
    ) {
      return toast({
        message: 'Please select date range of maximum 1 month!',
        type: 'error'
      });
    }
    setShowConfirmationModal(true);
  };
  const generateDownloadLink = async () => {
    setLoading(true);
    const url = getUrl(
      token,
      fromDate,
      toDate,
      projectId,
      userId,
      projectAgencyId,
      projectEqcTypeId,
      '/api/v1/report/generate-eqc-reports-link'
    );
    await axios
      .get(url)
      .then((res) => {
        Event(GA_EVENT.GENERATE_PROJECT_REPORT, {
          label: GA_LABEL.GENERATE_PROJECT_REPORT,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          query: `From: ${fromDate}, To: ${toDate}, Project Id: ${projectId}`,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        if (res?.data?.statusMessage) {
          toast({
            message: res?.data?.statusMessage,
            type: 'success'
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status !== 200) {
          toast({
            message: error?.response?.data?.error?.message,
            type: 'error'
          });
        }
      })
      .finally(() => {
        setLoading(false);
        setShowConfirmationModal(false);
      });
  };
  const menu = (
    <Menu className="report-dropdown-menu">
      <Menu.Item
        onClick={() => {
          downloadCsv(REPORTS_TAB_API_ROUTES.EQC_LOG_CSV, tenantId);
        }}
      >
        EQC Log (CSV)
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          downloadCsv(REPORTS_TAB_API_ROUTES.EQC_LATEST_LOG_CSV);
        }}
      >
        EQC Latest Log (CSV)
      </Menu.Item>
      {projectId && !userId && !projectEqcTypeId && !projectAgencyId && (
        <Menu.Item
          onClick={() => {
            downloadCsv(REPORTS_TAB_API_ROUTES.EQC_COUNT);
          }}
        >
          EQC Count (CSV)
        </Menu.Item>
      )}
      {CanPerform({ action: ALLOWED_ACTION_KEYS.GENERATE_REPORT }) &&
        projectId && (
          <Menu.Item onClick={handleGenerateClick}>
            EQC - QC Report (Link on Mail)
          </Menu.Item>
        )}
      {!projectEqcTypeId && (
        <>
          <Menu.Item
            onClick={() => {
              downloadCsv(
                REPORTS_TAB_API_ROUTES.INSTRUCTION_DETAILED,
                tenantId
              );
            }}
          >
            Instruction Detailed
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              downloadCsv(
                REPORTS_TAB_API_ROUTES.INSTRUCTION_LATEST_LOG,
                tenantId
              );
            }}
          >
            Instruction Latest log
          </Menu.Item>
        </>
      )}
    </Menu>
  );
  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CommonCard className="project-report mb-25">
      <Row className="d-flex align-center justify-between" gutter={[16, 16]}>
        <Col span={isDesktopViewport ? 5 : 24}>
          <h3>Project-wise Report</h3>
        </Col>
        <Col span={isDesktopViewport ? 19 : 24}>
          <Row
            className="reports-filters d-flex"
            justify={!isMobileViewport ? 'end' : 'start'}
            gutter={[0, 16]}
          >
            <Col span={isMobileViewport && 24} className="filter-div">
              <CommonDropdown
                allowClear
                showSearch
                placeholder="All Projects"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(e) => {
                  setCurrentUsersPage(0);
                  setCurrentAgencyPage(0);
                  setCurrentEqcTypePage(0);
                  setUserId(null);
                  setProjectAgencyId(null);
                  setProjectEqcTypeId(null);
                  if (e) {
                    setProjectSelected(true);
                    setProjectId(e);
                  } else {
                    setProjectSelected(false);
                    setProjectId();
                  }
                }}
                query={GET_PROJECT_DROPDOWN_LIST}
                fetchPolicy="network-only"
                responsePath="reportProjectDropdownList.data"
                valuePath="id"
                labelPath="name"
                optionKey="project"
              />
            </Col>
            <Col
              span={isMobileViewport && 24}
              className={!projectSelected ? 'disabled' : 'filter-div'}
            >
              <CommonDropdown
                allowClear
                showSearch
                placeholder="All Project Users"
                optionFilterProp="children"
                disabled={!projectId}
                onChange={(e) => {
                  if (e) {
                    setUserId(e);
                  } else {
                    setUserId();
                  }
                }}
                query={GET_PROJECT_USER_LIST}
                variables={{ filter: { projectId } }}
                fetchPolicy="network-only"
                responsePath="projectUserList.data"
                valuePath="user.id"
                labelPath="user.name"
                optionKey="project-users"
                currentPage={currentUsersPage}
                useEffectDeps={[projectId]}
                conditionToCheckBeforeQuery={!!projectId}
                projectId={projectId}
                value={userId}
              />
            </Col>
            <Col
              span={isMobileViewport && 24}
              className={!projectSelected ? 'disabled' : 'filter-div'}
            >
              <CommonDropdown
                allowClear
                showSearch
                placeholder="All Project Checklists"
                optionFilterProp="children"
                disabled={!projectId}
                onChange={(e) => {
                  if (e) {
                    setProjectEqcTypeId(e);
                  } else {
                    setProjectEqcTypeId();
                  }
                }}
                query={GET_PROJECT_EQC_TYPE_LIST}
                variables={{
                  filter: {
                    projectId: Number(projectId),
                    status: PROJECT_CHECKLIST_STATUS?.PUBLISHED
                  }
                }}
                fetchPolicy="network-only"
                responsePath="projectEqcTypeList.data"
                valuePath="id"
                labelPath="name"
                optionKey="project-eqc-types"
                currentPage={currentEqcTypePage}
                useEffectDeps={[projectId]}
                conditionToCheckBeforeQuery={!!projectId}
                projectId={projectId}
                value={projectEqcTypeId}
              />
            </Col>
            <Col
              span={isMobileViewport && 24}
              className={!projectSelected ? 'disabled' : 'filter-div'}
            >
              <CommonDropdown
                allowClear
                showSearch
                placeholder="All Project Agencies"
                optionFilterProp="children"
                disabled={!projectId}
                onChange={(e) => {
                  if (e) {
                    setProjectAgencyId(e);
                  } else {
                    setProjectAgencyId();
                  }
                }}
                query={GET_PROJECT_AGENCY_LIST}
                variables={{
                  filter: { projectId: Number(projectId) }
                }}
                fetchPolicy="network-only"
                responsePath="projectAgencyList.data"
                valuePath="agency.id"
                labelPath="agency.name"
                optionKey="project-agency"
                currentPage={currentAgencyPage}
                useEffectDeps={[projectId]}
                conditionToCheckBeforeQuery={!!projectId}
                projectId={projectId}
                value={projectAgencyId}
              />
            </Col>
            <Col span={isMobileViewport && 24}>
              <Dropdown overlay={menu}>
                <Button
                  shape="round"
                  className="width-percent-100 justify-center "
                  loading={loading}
                >
                  <span className="ml-10">Select Report</span>
                  <span>
                    <DownArrow className="ml-5" />
                  </span>
                </Button>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
      {!includes(currentUser?.roles, SYSTEM_ROLES?.SYSTEM_ADMIN) && (
        <Row className={isDesktopViewport ? 'mt-20' : 'mt-15'}>
          <Col>
            <div className="text-warning">
              <div>
                <b>Note:</b>
                <span className="ml-5">
                  {WARNINGS.REPORTS_FOR_PROJECT_ADMIN}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      )}
      <ConfirmationModal
        showModal={showConfirmationModal}
        setShowModal={setShowConfirmationModal}
        mutationFunc={() => generateDownloadLink()}
      />
    </CommonCard>
  );
};

export default ProjectReport;
