import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
  message
} from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import { get, includes, omit, trim, uniq } from 'lodash';
import momentTz from 'moment-timezone';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import {
  ADDONS,
  ADD_PROJECT_STEPS_KEYS,
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL,
  PERMISSIONS,
  REGEX,
  ROUTES
} from '../../../common/constants';
import { Event } from '../../../common/trackEvents';
import { formValidatorRules } from '../../../common/utils';
import GoogleMapComponent from '../../../components/GoogleMap';
import LoaderComponent from '../../../components/LoaderComponent';
import { CREATE_PROJECT, UPDATE_PROJECT } from '../graphql/Mutation';
import { GET_SINGLE_PROJECT } from '../graphql/Queries';

const { required, characterWithoutWhiteSpace } = formValidatorRules;
const timeZones = momentTz.tz.names();
const timezone = uniq(timeZones)?.map((timeZone, index) => ({
  key: index,
  value: timeZone,
  label: `${timeZone} (GMT${momentTz?.tz(timeZone)?.format('Z')})`
}));

const AddProjectDetails = ({ projectId }) => {
  const { getCurrentUser, getTenantUser, dispatch } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [form] = Form.useForm();
  const [marker, setMarker] = useState(null);
  const [radius, setRadius] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const isRfiOn = useWatch('isRfiActive', form);
  const tenantUser = getTenantUser();
  const history = useHistory();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  const [fetchProjectDetails, { loading }] = useLazyQuery(GET_SINGLE_PROJECT, {
    variables: { id: Number(projectId) },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      dispatch({
        type: 'SET_PROJECT_DETAILS',
        data: JSON.stringify(res?.getProject)
      });
      const formData = {
        ...res?.getProject,
        radius: String(res?.getProject?.radius)
      };
      if (res?.getProject?.permissions?.includes(PERMISSIONS.FINGERPRINT)) {
        formData.fingerprint = true;
      }
      if (res?.getProject?.permissions?.includes(PERMISSIONS.LOCATION)) {
        formData.location = true;
      }
      form.setFieldsValue(formData);

      setMarker({
        lat: res?.getProject?.latitude,
        lng: res?.getProject?.longitude
      });
      setRadius(Number(res?.getProject?.radius));
    },
    onError() {}
  });

  useMemo(() => {
    form.setFieldsValue({ timeZone: tenantUser?.tenant?.timeZone });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantUser?.tenant?.timeZone]);

  const [createProject, { loading: createLoading }] = useMutation(
    CREATE_PROJECT,
    {
      onError() {},
      onCompleted: (res) => {
        if (res?.createProject) {
          message.success('Project created successfully!');
          Event(GA_EVENT.ADD_NEW_PROJECT, {
            label: GA_LABEL.ADD_NEW_PROJECT,
            // eslint-disable-next-line no-undef
            pathname: window?.location?.href,
            user_id: currentUser?.id,
            user_name: currentUser?.name,
            tenant_id: currentUser?.tenantUser?.tenant?.id,
            tenant_name: currentUser?.tenantUser?.tenant?.organizationName
          });
          history.push(
            `${ROUTES.ADD_PROJECTS}/${Number(res?.createProject?.id)}/${
              ADD_PROJECT_STEPS_KEYS.USERS
            }`
          );
        }
      }
    }
  );

  const [updateProject, { loading: updateLoading }] = useMutation(
    UPDATE_PROJECT,
    {
      onError() {},
      onCompleted: () => {
        Event(GA_EVENT.EDIT_NEW_PROJECT, {
          label: GA_LABEL.EDIT_NEW_PROJECT,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        history.push(
          `${ROUTES.ADD_PROJECTS}/${projectId}/${ADD_PROJECT_STEPS_KEYS.USERS}`
        );
      }
    }
  );

  const formSubmit = (values) => {
    const { location, fingerprint } = values;
    const permissions = [];
    if (location) {
      permissions.push(PERMISSIONS.LOCATION);
    }
    if (fingerprint) {
      permissions.push(PERMISSIONS.FINGERPRINT);
    }
    const data = omit({ ...values, permissions }, [
      'location',
      'fingerprint',
      'logo'
    ]);

    data.radius = Number(data?.radius);
    if (projectId) {
      updateProject({
        variables: { data, id: Number(projectId) }
      });
    } else {
      createProject({
        variables: { data }
      });
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchProjectDetails({ variables: { id: Number(projectId) } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFieldsValue({ latitude: marker?.lat, longitude: marker?.lng });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marker]);

  if (loading) return <LoaderComponent setHeight={0} />;
  return (
    <>
      <Form form={form} layout="vertical" onFinish={formSubmit}>
        <Row gutter={[18]}>
          <Col span={isDesktopViewport ? 8 : 24}>
            <Form.Item
              rules={[
                required,
                characterWithoutWhiteSpace,
                { max: 50, message: 'Maximum 50 characters allowed' }
              ]}
              name="name"
              label="Name"
            >
              <Input allowClear placeholder="Enter Name" />
            </Form.Item>
          </Col>
          <Col span={isDesktopViewport ? 8 : 24}>
            <Form.Item
              rules={[
                required,
                characterWithoutWhiteSpace,
                { max: 20, message: 'Maximum 20 characters allowed' }
              ]}
              name="uniqueCode"
              label="Unique Code"
            >
              <Input allowClear placeholder="Enter Project Code" />
            </Form.Item>
          </Col>
          <Col span={isDesktopViewport ? 8 : 24}>
            <Form.Item
              rules={[
                required,
                characterWithoutWhiteSpace,
                { max: 50, message: 'Maximum 50 characters allowed' }
              ]}
              name="clientName"
              label="Client Name"
            >
              <Input allowClear placeholder="Enter Client Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  max: 1000,
                  message: 'Description cannot be more than 1000 characters'
                }
              ]}
            >
              <Input.TextArea
                allowClear
                autoSize={{ minRows: 3, maxRows: 6 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="mb-10">Permissions</div>
        <Space size={isDesktopViewport ? 50 : 25} className="mb-20">
          <div className="d-flex align-center">
            <div className="mr-16">Location:</div>
            <Form.Item name="location" className="m-0" valuePropName="checked">
              <Switch />
            </Form.Item>
          </div>
          <div className="d-flex align-center">
            <div className="mr-16">Authentication:</div>
            <Form.Item
              name="fingerprint"
              className="m-0"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </div>
        </Space>
        <div className="mb-10">Access</div>
        <Space size={isDesktopViewport ? 50 : 25}>
          <div className="d-flex align-center">
            <div className="mr-16">RFI:</div>
            <Form.Item
              name="isRfiActive"
              className="m-0"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </div>
        </Space>
        {isRfiOn && !includes(tenantUser?.tenant?.addOn, ADDONS.RFI) && (
          <div className="text-warning mb-15">
            This feature is not available for your organization. Request for RFI
            will be sent to support team when project is created.
          </div>
        )}
        <h3 className="mt-25">Site Details</h3>
        <Row gutter={[18]}>
          <Col span={isDesktopViewport ? 6 : 24}>
            <Form.Item rules={[required]} name="latitude" label="Latitude">
              <InputNumber placeholder="Latitude" disabled />
            </Form.Item>
            <Form.Item rules={[required]} name="longitude" label="Longitude">
              <InputNumber placeholder="Longitude" disabled />
            </Form.Item>
          </Col>
          <Col span={isDesktopViewport ? 18 : 24}>
            <Row gutter={[18]}>
              <Col span={isDesktopViewport ? 8 : 24}>
                <Form.Item
                  rules={[
                    required,
                    {
                      pattern: REGEX.NUMBER,
                      message: 'Enter a valid number'
                    },
                    {
                      max: 7,
                      message: 'Value cannot be more than 7 characters'
                    }
                  ]}
                  name="radius"
                  label="Radius (m)"
                >
                  <Input
                    allowClear
                    placeholder="Enter Radius"
                    onChange={(e) => setRadius(Number(e?.target?.value))}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={isDesktopViewport ? 16 : 24}>
                <Form.Item name="timeZone" label="TimeZone" rules={[required]}>
                  <Select
                    showSearch
                    placeholder="Select Timezone"
                    getPopupContainer={(triggerNode) =>
                      triggerNode?.parentElement
                    }
                    filterOption={(input, option) => {
                      const label = get(option, 'label', '');
                      return label
                        ?.toLowerCase()
                        ?.includes(trim(input?.toLowerCase()));
                    }}
                    options={timezone}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <GoogleMapComponent
          marker={marker}
          setMarker={setMarker}
          radius={radius}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
        <Row justify="end">
          <Button
            shape="round"
            type="primary"
            htmlType="submit"
            loading={createLoading || updateLoading}
          >
            Next
          </Button>
        </Row>
      </Form>
    </>
  );
};

export default AddProjectDetails;
