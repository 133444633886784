import { SettingOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Dropdown, Popover, Tabs, Tag } from 'antd';
import { findIndex, includes, isNaN, values } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../AppContext';
import Error404 from '../../../../Error404';
import {
  ForwardArrow,
  InfoIcon,
  ProjectIcon,
  Timezone
} from '../../../../assets/svg';
import {
  ACCESS_TYPE,
  ADDONS,
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  CHECKLIST_STATUS_LABEL,
  CONFIRMATION_TYPES,
  GA_EVENT,
  GA_LABEL,
  REQUEST_FEATURE_UPGRADE_KEYS,
  ROUTES,
  TAB_KEYS,
  USER_ROLES
} from '../../../../common/constants';
import { Event } from '../../../../common/trackEvents';
import { titleCase } from '../../../../common/utils';
import CanPerform from '../../../../components/CanPerform';
import HasAccess from '../../../../components/HasAccess';
import UpgradeModal from '../../../../components/UpgradeModal';
import { CREATE_PROJECT_RFI_DRAFT } from '../../graphql/Mutation';
import { GET_SINGLE_PROJECT } from '../../graphql/Queries';
import EditProjectModal from '../EditProjectModal';
import ReportConfigModal from '../ReportConfigModal';
import Agency from './pages/agencies/Agency';
import UnassignAgency from './pages/agencies/UnassignAgency';
import Eqc from './pages/eqc/Eqc';
import EqcType from './pages/eqcTypes/EqcTypes';
import Instruction from './pages/instructions/Instruction';
import Nomenclature from './pages/nomentclature/Nomenclature';
import PlanAndTrack from './pages/planAndTrack/PlanAndTack';
import ConfirmationModal from './pages/rfi/ConfirmationModal';
import RfiActivationRequiredModal from './pages/rfi/RfiActivationRequiredModal';
import User from './pages/users/User';

const ProjectDetails = () => {
  const { getCurrentUser, getTenantUser, state, dispatch } = useContext(
    AppContext
  );
  const currentUser = getCurrentUser();
  const { projectId, tab } = useParams();
  const { TabPane } = Tabs;
  const history = useHistory();
  const { pathname } = useLocation();
  const navFilter = history?.location?.state?.navFilter;
  const navPagination = history?.location?.state?.navPagination;
  const currentPath = history?.location?.state?.currentPath;
  const [showEditModal, setShowEditModal] = useState(false);
  const [showReportConfigModal, setShowReportConfigModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [featureType, setFeatureType] = useState();
  const [
    showRfiActivationRequiredModal,
    setShowRfiActivationRequiredModal
  ] = useState(false);
  const [hasInstructionAccess, setHasInstructionAccess] = useState(false);
  const [confirmationType, setConfirmationType] = useState(
    CONFIRMATION_TYPES?.EDIT
  );
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [alreadyDraftedUserData, setAlreadyDraftedUserData] = useState();
  const [showModal, setShowModal] = useState(false);
  const currentUserId = getCurrentUser()?.id || {};
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);
  const tenantUser = getTenantUser();
  const hasTargetAccess = includes(
    tenantUser?.tenant?.addOn,
    ADDONS?.TARGET_ACTUAL_METRIC
  );

  const redirectTab = (tabKey) => {
    history.push(`${ROUTES.PROJECTS}/${projectId}/${tabKey}`);
  };

  const RedirectConditions = ({ tab: tabName }) => {
    switch (tabName) {
      case TAB_KEYS.EQC:
        if (!HasAccess({ type: ACCESS_TYPE.INSPECTION })) {
          redirectTab(TAB_KEYS.INSTRUCTION);
        }
        break;
      case TAB_KEYS.INSTRUCTION:
        if (!HasAccess({ type: ACCESS_TYPE.INSTRUCTION })) {
          redirectTab(TAB_KEYS.EQC);
        }
        break;
      case TAB_KEYS.TARGET:
        if (!(HasAccess({ type: ACCESS_TYPE.INSPECTION }) && hasTargetAccess)) {
          redirectTab(TAB_KEYS.EQC);
        }
        break;
      default:
        break;
    }
    return null;
  };

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.push(currentPath, {
          navFilter,
          navPagination
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    if (includes(state?.currentUser?.roles, USER_ROLES?.SYSTEM_ADMIN)) {
      setIsSystemAdmin(true);
    } else {
      setIsSystemAdmin(false);
    }
  }, [state?.currentUser]);

  const [getWebProject, { data, loading }] = useLazyQuery(GET_SINGLE_PROJECT, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const index = findIndex(
        res?.getProject?.projectUsers,
        (user) => Number(user?.userId) === Number(currentUserId)
      );
      dispatch({
        type: 'SET_PROJECT_DETAILS',
        data: JSON.stringify(res?.getProject)
      });
      if (index !== -1) {
        const loggedInUserRole = res?.getProject?.projectUsers?.[index]?.roles;
        dispatch({ type: 'SET_PROJECT_ROLE', data: loggedInUserRole });
        if (
          includes(
            res?.getProject?.projectUsers?.[index]?.access,
            ACCESS_TYPE.INSTRUCTION
          )
        ) {
          setHasInstructionAccess(true);
        }
      } else {
        dispatch({ type: 'SET_PROJECT_ROLE', data: '' });
      }
    }
  });

  const [createProjectRFIDraft] = useMutation(CREATE_PROJECT_RFI_DRAFT, {
    onCompleted(res) {
      if (res) {
        Event(GA_EVENT.CREATE_PROJECT_RFI_DRAFT, {
          label: GA_LABEL.CREATE_PROJECT_RFI_DRAFT,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        setShowRfiActivationRequiredModal(false);
        if (!res?.createProjectRFIDraft?.draftCreatorInfo) {
          history.push(
            `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.RFI}/${res?.createProjectRFIDraft?.projectRFIId}/draft`
          );
          setShowModal(false);
        } else {
          setShowModal(false);
          setAlreadyDraftedUserData(res?.createProjectRFIDraft);
          setConfirmationType(CONFIRMATION_TYPES?.MULTIPLE_USER);
          setShowModal(true);
        }
      }
    },
    onError() {}
  });

  const createRfi = () => {
    if (
      data?.getProject?.projectRFIs?.length > 0 &&
      data?.getProject?.isRfiActive
    ) {
      history.push(
        `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.RFI}/${
          data?.getProject?.projectRFIs?.[0]?.id
        }${
          data?.getProject?.projectRFIs?.[0]?.status ===
          CHECKLIST_STATUS_LABEL.DRAFT
            ? '/draft'
            : ''
        }`
      );
    } else {
      createProjectRFIDraft({
        variables: {
          data: { projectId }
        }
      });
    }
  };

  useEffect(() => {
    if (includes(values(TAB_KEYS), tab) && !isNaN(Number(projectId))) {
      getWebProject({
        variables: {
          id: Number(projectId)
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch({ type: 'SET_PROJECT_ROLE', data: '' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    !includes(values(TAB_KEYS), tab) ||
    isNaN(Number(projectId)) ||
    tab === TAB_KEYS.RFI
  ) {
    return <Error404 />;
  }
  const items = [
    CanPerform({ action: ALLOWED_ACTION_KEYS.EDIT_PROJECT }) && {
      key: '1',
      label: (
        <div
          className="edit-button-project d-flex"
          onClick={() => setShowEditModal(true)}
        >
          Project
        </div>
      )
    },
    HasAccess({ type: ACCESS_TYPE.INSPECTION }) &&
      CanPerform({
        action: ALLOWED_ACTION_KEYS.RFI_SETUP,
        type: ALLOWED_ACTION_TYPE.BOTH
      }) && {
        key: '2',
        label: (
          <div
            className="edit-button-project-rfi d-flex mr-10"
            onClick={() => {
              if (!includes(tenantUser?.tenant?.addOn, ADDONS.RFI)) {
                setShowUpgradeModal(true);
                setFeatureType(ADDONS.RFI);
              } else if (
                includes(tenantUser?.tenant?.addOn, ADDONS.RFI) &&
                !data?.getProject?.isRfiActive
              ) {
                setShowRfiActivationRequiredModal(true);
              } else {
                createRfi();
              }
            }}
          >
            RFI
          </div>
        )
      },
    HasAccess({ type: ACCESS_TYPE.INSPECTION }) &&
      CanPerform({
        action: ALLOWED_ACTION_KEYS.REPORT_CONFIG,
        type: ALLOWED_ACTION_TYPE.BOTH
      }) && {
        key: '3',
        label: (
          <div
            className="edit-button-project d-flex"
            onClick={() => {
              if (!includes(tenantUser?.tenant?.addOn, ADDONS.REPORT_CONFIG)) {
                setShowUpgradeModal(true);
                setFeatureType(ADDONS.REPORT_CONFIG);
              } else {
                setShowReportConfigModal(true);
              }
            }}
          >
            EQC report
          </div>
        )
      }
  ];
  return (
    <>
      <div className="project-details">
        <div className="project-details-title d-flex justify-between align-center page-header">
          <div className="project-details-title-text d-flex">
            <ProjectIcon
              onClick={() => {
                history.push(ROUTES.PROJECTS, {
                  navFilter,
                  navPagination
                });
              }}
              className="pointer"
            />
            <ForwardArrow />
            <div>
              <h1 className="mr-10 text-break">{data?.getProject?.name}</h1>
              {data?.getProject?.timeZone && (
                <div className="d-flex align-center timezone-div">
                  <Timezone title="timeZone" />
                  {data?.getProject?.timeZone}
                </div>
              )}
            </div>
          </div>
          <div className="d-flex align-center">
            {CanPerform({
              action: ALLOWED_ACTION_KEYS.PROJECT_SETTING,
              type: ALLOWED_ACTION_TYPE.BOTH
            }) && (
              <Dropdown
                menu={{ items }}
                trigger={['click']}
                placement="bottomRight"
              >
                <Button
                  shape="round"
                  type="primary"
                  icon={<SettingOutlined />}
                  className="setting-button fw-medium d-flex align-center"
                >
                  Setting
                </Button>
              </Dropdown>
            )}
          </div>
        </div>
        <div className="mobile-card-wrapper">
          <div className="details-content">
            {/* * RedirectConditions component is created to redirect if entered tab name is not
            rendered conditionally */}
            <RedirectConditions tab={tab} />
            <Tabs
              onTabClick={(key) => {
                getWebProject({
                  variables: {
                    id: Number(projectId)
                  }
                });
                history.push(`${ROUTES.PROJECTS}/${projectId}/${key}`, {
                  navFilter,
                  navPagination
                });
              }}
              activeKey={tab}
              destroyInactiveTabPane
            >
              {HasAccess({ type: ACCESS_TYPE.INSPECTION }) && (
                <TabPane tab="Eqc" key={TAB_KEYS.EQC}>
                  <div className="details">
                    <Eqc uniqueCode={data?.getProject?.uniqueCode} />
                  </div>
                </TabPane>
              )}
              {HasAccess({ type: ACCESS_TYPE.INSTRUCTION }) &&
                (hasInstructionAccess || isSystemAdmin) && (
                  <TabPane tab="Issue" key={TAB_KEYS.INSTRUCTION}>
                    <div>
                      <Instruction uniqueCode={data?.getProject?.uniqueCode} />
                    </div>
                  </TabPane>
                )}
              {HasAccess({ type: ACCESS_TYPE.INSPECTION }) && (
                <TabPane tab="Checklists" key={TAB_KEYS.CHECKLISTS}>
                  <div>
                    <EqcType />
                  </div>
                </TabPane>
              )}
              <TabPane tab="User" key={TAB_KEYS.USERS}>
                <div>
                  <User />
                </div>
              </TabPane>
              <TabPane tab="Agencies" key={TAB_KEYS.AGENCIES}>
                <div>
                  {includes(pathname, 'unassigned') ? (
                    <UnassignAgency />
                  ) : (
                    <Agency />
                  )}
                </div>
              </TabPane>
              {HasAccess({ type: ACCESS_TYPE.INSPECTION }) && hasTargetAccess && (
                <TabPane tab={titleCase(TAB_KEYS.TARGET)} key={TAB_KEYS.TARGET}>
                  <div>
                    <PlanAndTrack />
                  </div>
                </TabPane>
              )}
              {HasAccess({ type: ACCESS_TYPE.INSPECTION }) && (
                <TabPane
                  tab={
                    <div className="d-flex align-center">
                      {titleCase(TAB_KEYS.NOMENCLATURE)}
                      {data?.getProject?.nomenclatureCount > 0
                        ? tab === TAB_KEYS.NOMENCLATURE && (
                            <Popover
                              placement="bottom"
                              overlayClassName="nomenclature-info"
                              content={
                                <>
                                  <h3>Nomenclature feature Guide:</h3>
                                  <ol>
                                    <li>
                                      <b>Create:</b> System admin and Project
                                      admin can create nomenclature, there are 2
                                      options through CSV file import or by
                                      adding level manually.
                                    </li>
                                    <li>
                                      <b>Go live:</b> Once nomenclature is
                                      created, click on "Go live" button. All
                                      the project users will be sent
                                      notification that this feature is
                                      activated.
                                    </li>
                                    <li>
                                      <b>Live:</b> Once live all the mobile user
                                      will see the hierarchy in their mobile
                                      phone for that project.
                                    </li>
                                    <li>
                                      <b>Editing:</b> System admin or Project
                                      admin can from projects nomenclature tab
                                      and this will be reflected in mobile user
                                      once application is refreshed.
                                    </li>
                                  </ol>
                                </>
                              }
                              trigger={[!isDesktopViewport ? 'click' : 'hover']}
                              getPopupContainer={() =>
                                // eslint-disable-next-line no-undef
                                document.querySelector('.project-details')
                              }
                            >
                              <InfoIcon
                                className="ml-5 nomenclature-info-button"
                                height="18px"
                                width="18px"
                                onClick={(e) => e.stopPropagation()}
                              />
                            </Popover>
                          )
                        : !loading && (
                            <Tag className="tag-new ml-5" color="#202A3F">
                              NEW
                            </Tag>
                          )}
                    </div>
                  }
                  key={TAB_KEYS.NOMENCLATURE}
                  className="nomenclature-tab"
                >
                  <div>
                    <Nomenclature
                      projectData={data?.getProject}
                      projectDataRefetch={getWebProject}
                    />
                  </div>
                </TabPane>
              )}
            </Tabs>
          </div>
        </div>
      </div>
      <div id="tabs-footer" />
      {showEditModal && (
        <EditProjectModal
          setShowModal={setShowEditModal}
          showModal={showEditModal}
          projectId={projectId}
          callback={getWebProject}
        />
      )}
      {showReportConfigModal && (
        <ReportConfigModal
          setShowModal={setShowReportConfigModal}
          showModal={showReportConfigModal}
          projectId={projectId}
          projectData={data?.getProject}
        />
      )}
      {showUpgradeModal && (
        <UpgradeModal
          showModal={showUpgradeModal}
          setShowModal={setShowUpgradeModal}
          projectId={projectId}
          featureKey={featureType}
          isAlreadyRequested={
            data?.getProject?.[
              featureType === REQUEST_FEATURE_UPGRADE_KEYS.RFI
                ? 'isRfiRequestSent'
                : 'isReportConfigRequestSent'
            ]
          }
          refetch={() => {
            getWebProject({
              variables: {
                id: Number(projectId)
              }
            });
          }}
        />
      )}
      {showRfiActivationRequiredModal && (
        <RfiActivationRequiredModal
          showModal={showRfiActivationRequiredModal}
          setShowModal={setShowRfiActivationRequiredModal}
          onOk={() => {
            createRfi();
          }}
        />
      )}
      {showModal && (
        <ConfirmationModal
          type={confirmationType}
          showModal={showModal}
          setShowModal={setShowModal}
          handleConfirmation={() => {
            createProjectRFIDraft({
              variables: {
                data: {
                  projectId,
                  discardDraft: !!alreadyDraftedUserData?.draftCreatorInfo
                }
              }
            });
          }}
          alreadyDraftedUserData={alreadyDraftedUserData}
          setConfirmationType={setConfirmationType}
          setIsActionLoading={setIsActionLoading}
          isActionLoading={isActionLoading}
          setAlreadyDraftedUserData={setAlreadyDraftedUserData}
        />
      )}
    </>
  );
};

export default ProjectDetails;
